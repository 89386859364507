import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import axios from 'axios';
import { API_ADR, SpinnerCentered } from '../../ApiCenter/API_GET';
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';



export const ProjectInfoEdit = forwardRef((props, ref) => {

    const defaultSettings = {
        showModal: false,
        isLoading: true,
        disabled: false,
    }
    const [modalSettings, setModalSettings] = useState(defaultSettings)

    const [dataInfoPanel, setDataInfoPanel] = useState({})

    useEffect(() => {

        async function fetchData() {

            const result = await axios.get(API_ADR(`editInfo-${modalSettings.type}=${modalSettings.idElement}`));
        
            setDataInfoPanel(result.data)  
            setModalSettings(previous => ({
                ...previous,
                isLoading: false,
                showModal: true,

            }))         

        }
        if(modalSettings.type !== undefined && modalSettings.showModal === false){
            fetchData()
        }

    }, [modalSettings])

    useImperativeHandle(ref, () => ({
        openInfoModal(props) {
            setModalSettings(previous => ({
                ...previous,
                type: props.lineContent,
                idElement: props.idElement,
                project_name: props.project_name,
            }))
        }
    }));

    const closePanel = (reloadOrNot) => {
        setModalSettings(defaultSettings)
       props.close()
    }

    async function storeInfo(data){



        const headers = {
            'Content-Type': 'text/plain'
        };

        // eslint-disable-next-line
        await axios.post(
            API_ADR(`editInfo-${modalSettings.type}-savedata`),
            data,
            {headers}
        ).then(function (result) {

            props.close('reload')

            setTimeout(() => {
            setModalSettings(defaultSettings)
            }, 500)

        })
        .catch(function (error) {
            
        });
    }

    if(modalSettings.type !== undefined &&modalSettings.isLoading){
        return <SpinnerCentered />
    }


    return modalSettings.showModal && (
    <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
        <div className="modal-dialog">
            <div className="modal-content" id="AudioMateWindowMenu">
                <div className="modal-header" style={{padding: '7px 10px 5px 16px'}}>
                    <h5 className="modal-title">
                        {modalSettings.type === 'project' &&
                            <span className="iconprbchscrp badge bg-primary" style={{padding: '7px 8px 4px 9px', marginRight: '7px', marginTop: '6px'}}>P</span>
                        }
                        {modalSettings.type === 'batch' &&
                            <span className="iconprbchscrp badge bg-success" style={{padding: '7px 8px 4px 9px', marginRight: '7px', marginTop: '6px'}}>B</span>
                        }
                        {modalSettings.type === 'script' &&
                            <span className="iconprbchscrp badge bg-danger" style={{padding: '7px 8px 4px 9px', marginRight: '7px', marginTop: '6px'}}>S</span>
                        }
                            
                        <span>{modalSettings.project_name}</span>

                    </h5>
                    <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => closePanel('')}>🗙</button>    
                </div>
                
                {modalSettings.type === 'project' && <ProjectForm data={dataInfoPanel} onClosePanel={closePanel} sendDataParent={storeInfo} />}
                {modalSettings.type === 'batch' && <BatchForm data={dataInfoPanel} onClosePanel={closePanel} sendDataParent={storeInfo} />}
                {modalSettings.type === 'script' && <ScriptForm  data={dataInfoPanel}  onClosePanel={closePanel} sendDataParent={storeInfo} />}

            </div>
        </div>
    </div>)
})

const ProjectForm = (props) => {

    const [dataInfoPanel, setDataInfoPanel] = useState()

    useEffect(() => {
        if(props.data['info'] !== undefined){
            setDataInfoPanel(props.data)
        }
    }, [props])

    const changeStatus = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.target.value
            }
        }))
    }

    const changeColor = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.currentValue.hex
            }
        }))
    }



    async function onSubmitHandler(e){
        e.preventDefault()

        let checkPreviousOpenScript = parseInt(dataInfoPanel.info.status_batch)

        if(checkPreviousOpenScript < 1 && dataInfoPanel.info.closed === '1'){
            setDataInfoPanel(previous => ({
                ...previous,
                warning: true
            }))
            return
        }
       
        setDataInfoPanel(previous => ({
            ...previous,
            disabled: true
        }))

        props.sendDataParent(dataInfoPanel.info)
    }

    const removeAlert = () => {
        setDataInfoPanel(previous => ({
            ...previous,
            warning: false
        }))
    }

    /* continue and force close all scripts */
    const forceClose = () => {
        setDataInfoPanel(previous => ({
            info: {
                ...previous.info,

                forceclose: true,
                disabled: true
            },
            ...previous,
            warning: false
        }))
        dataInfoPanel.info.forceclose = true
        props.sendDataParent(dataInfoPanel.info)
    }

    let divAlertStyle = {position: 'absolute', width: '100%', height: '488px', background: '#7f7f7f40', zIndex: '10', borderRadius: '8px', margin: '-78px -4px'};
    
    if(dataInfoPanel !== undefined){
    
    return <><form onSubmit={(e) => onSubmitHandler(e)}>
        <div className="modal-body" style={{padding: '7px 12px 0px 4px'}}>
            {dataInfoPanel.warning && <div style={divAlertStyle}>
                <div className="alert alert-danger" role="alert" style={{margin: '136px 10px 0px 10px'}}>
                    <h6 className="alert-heading">Warning!</h6>
                    <p>There are some Batch/(es) of this project which are still open. This operation will close them</p>
                    <hr />
                    
                    <button type="button" className="btn btn-secondary btn-sm" style={{width: '100px', margin: '0px 0px 0px 10px'}}
                    onClick={removeAlert}>Abort</button>
                    <button type="button" className="btn btn-primary btn-sm" style={{width: '100px', margin: '0px 0px 0px 10px'}}
                    onClick={forceClose}>Continue</button>
                </div>
            </div>}
            <div className="form-floating form-select-sm col-md-12">
                <input id="project_name" type="text" className="form-control" required={true} autoComplete="off" spellCheck="false"
                value={dataInfoPanel.info.project_name}
                onChange={(e) => changeStatus(e, 'project_name')}/>
                <label htmlFor="project_name">Project Name</label>
            </div>
            <div className="form-floating form-select-sm col-md-12">
                <input id="real_name" type="text" className="form-control" autoComplete="off" spellCheck="false"
                value={dataInfoPanel.info.real_name}
                onChange={(e) => changeStatus(e, 'real_name')}/>
                <label htmlFor="real_name">Real Name</label>
            </div>
            <div className="form-floating form-select-sm col-md-12">
                <input id="bps_code" type="text" className="form-control" autoComplete="off" spellCheck="false"
                value={dataInfoPanel.info.bps_code}
                onChange={(e) => changeStatus(e, 'bps_code')}
                />
                <label htmlFor="bps_code">BPS Code</label>
            </div>
            <div className="row">
                <div className="form-floating form-select-sm col-md-6"  style={{left:'13px'}}>
                    <select id="client_name" className="form-select form-select-sm"
                    value={dataInfoPanel.info.client_id}
                    onChange={(e) => changeStatus(e, 'client_id')}
                    >
                        <option value="0">Select</option>
                        {dataInfoPanel.clients.map((client, index) => {
                            return <option key={index} value={client.id}>{client.client_name}</option>
                        })}
                    </select>
                    <label htmlFor="client_name">Client Name</label>
                </div>
                <div className="form-floating form-select-sm col-md-6">
                    <select id="franchise" className="form-select form-select-sm" 
                    value={dataInfoPanel.info.franchise_id}
                    onChange={(e) => changeStatus(e, 'franchise_id')}
                    >
                        <option value="0">Select</option>
                        {dataInfoPanel.franchises.map((franchise, index) => {
                            return <option key={index} value={franchise.id}>{franchise.franchise_name}</option>
                        })}
                    </select>
                    <label htmlFor="franchise">Franchise</label>
                </div>
            </div>
            <div className="row">
                <div className="form-floating form-select-sm col-md-6" style={{left:'13px'}}>
                    <select className="form-select form-select-sm" id="status"
                    value={dataInfoPanel.info.closed}
                    onChange={(e) => changeStatus(e, 'closed')}
                    >
                        <option value="-1" style={{background: '#ffc107'}}>Not in production</option>
                        <option value="0" style={{background: '#0d6efd', color: '#FFF'}}>In Progress</option>
                        <option value="1" style={{background: '#198754', color: '#FFF'}}>Closed</option>
                    </select>
                    <label htmlFor="status">Status</label>
                </div>

                <div className="form-floating form-select-sm col-md-6">
                <div style={{border: '1px solid #CCC', borderRadius: '3px', height: '57px', width: '233px'}}>
                    <div style={{color: '#888', fontSize: '12px', margin: '2px 4px'}}>Color</div>
                    <ColorPickerComponent 
                        id="color-picker" 
                        value={dataInfoPanel.info.project_color}
                        change={(e) => changeColor(e, 'project_color')} />                        
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div className="text-center">
            <button type="submit" className="btn btn-primary btn-sm mb-3" style={{'width': '100px'}} disabled={dataInfoPanel.disabled}>Apply</button>
        </div>
        </form>

    </>}
}

const BatchForm = (props) => {

    const dateStart = useRef(null);
    const dateEnd = useRef(null);

    const onFocusStart = (args) => {
        dateStart.show();
    }
    const onFocusEnd = (args) => {
        dateEnd.show();
    }

    const [dataInfoPanel, setDataInfoPanel] = useState()

    useEffect(() => {
        if(props.data['info'] !== undefined){
            setDataInfoPanel({...props.data, warning: false})
        }
    }, [props])

    const changeStatus = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.target.value
            }
        }))
    }

    const changeFormValuesDatePicker = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: reFormatDate(event.value)
            }
        }))
    }

    async function onSubmitHandler(e){
        e.preventDefault()

        if(dayjs(dataInfoPanel.info.start_date).isAfter(dayjs(dataInfoPanel.info.end_date))){
            toast.error('Check the dates entered. End Date is before Start Date');
            return
        } 


        let checkPreviousOpenScript = parseInt(dataInfoPanel.info.status_script)
        if(dataInfoPanel.info['forceclose'] === undefined){

            if(checkPreviousOpenScript < 1 && dataInfoPanel.info.closed === '1'){
                setDataInfoPanel(previous => ({
                    ...previous,
                    warning: true
                }))
                return
            }
        }

        setDataInfoPanel(previous => ({
            ...previous,
            disabled: true
        }))

        props.sendDataParent(dataInfoPanel.info)
    }


    /* remove window alert */
    const removeAlert = () => {
        setDataInfoPanel(previous => ({
            ...previous,
            warning: false
        }))
    }

    /* continue and force close all scripts */
    const forceClose = () => {
        setDataInfoPanel(previous => ({
            info: {
                ...previous.info,
                forceclose: true,
                disabled: true
            },
            ...previous,
            warning: false
        })) 
        dataInfoPanel.info.forceclose = true
        props.sendDataParent(dataInfoPanel.info)
    }


    const changeSwitch = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.target.checked ? 1 : 0
            }
        }))
    }



    let divAlertStyle = {position: 'absolute', width: '100%', height: '425px', background: '#7f7f7f40', zIndex: '10', borderRadius: '8px', margin: '-78px -4px'};
    if(dataInfoPanel !== undefined){

    return (
    <>
    <form onSubmit={(e) => onSubmitHandler(e)}><div className="modal-body" style={{padding: '7px 12px 0px 4px'}}>
        {dataInfoPanel.warning && <div style={divAlertStyle}>
            <div className="alert alert-danger" role="alert" style={{margin: '95px 10px 0px 10px'}}>
                <h6 className="alert-heading">Warning!</h6>
                <p>There are some scripts of this batch which are still open. This operation will close them</p>
                <hr />
                
                <button type="button" className="btn btn-secondary btn-sm" style={{width: '100px', margin: '0px 0px 0px 10px'}}
                onClick={removeAlert}>Abort</button>
                <button type="button" className="btn btn-primary btn-sm" style={{width: '100px', margin: '0px 0px 0px 10px'}}
                onClick={forceClose}>Continue</button>
            </div>
        </div>
        }
        <div className="form-floating form-select-sm col-12">
            <ToastContainer />
            <input id="batch_name" type="text" className="form-control" required={true} autoComplete="off" spellCheck="false"
            value={dataInfoPanel.info.batch_name} onChange={(e) => changeStatus(e, 'batch_name')} />
            <label htmlFor="batch_name">Batch Name</label>
        </div>
        <div className="row">
            <div className="form-floating form-select-sm col-6" style={{left:'13px'}}>
                <select id="studio_lead" className="form-select form-select-sm" 
                value={dataInfoPanel.info.lead_studio_id}
                onChange={(e) => changeStatus(e, 'lead_studio_id')} >
                    <option value="0">Select</option>
                    {dataInfoPanel.studios.map((studio, index) => {
                        return <option key={index} value={studio.id}>{studio.site_code}</option>
                    })}
                </select>
                <label htmlFor="studio_lead">Studio Lead</label>
            </div>
            <div className="form-floating form-select-sm col-6">
                <select id="pm_name" className="form-select form-select-sm"
                value={dataInfoPanel.info.pm_id}
                onChange={(e) => changeStatus(e, 'pm_id')} >
                    <option value="0">Select</option>
                    {dataInfoPanel.pms.map((pm, index) => {
                        return <option key={index} value={pm.id}>{pm.pm_name}</option>
                    })}
                </select>
                <label htmlFor="pm_name">PM</label>
            </div>
        </div>
        <div className="row">
                <div className="col-md-6 mt-1" style={{border: '1px solid #CCC', width: '231px', marginLeft: '21px', borderRadius: '3px'}}>
                    <label htmlFor="batch_start">Start Date</label>
                        <DatePickerComponent id="batch_start" name="booking_date_r"                            
                            ref={dateStart}
                            openOnFocus={onFocusStart}
                            start={onFocusStart}
                            value={AM_dateTime_ExtractDate(dataInfoPanel.info.start_date)}
                            change={(e) => changeFormValuesDatePicker(e, 'start_date')}
                            format='d-MMM-yyyy' 
                            placeholder='Enter date'
                    />
                </div>
                <div className="col-md-6 mt-1" style={{border: '1px solid #CCC', width: '231px', marginLeft: '10px', borderRadius: '3px'}}>
                    <label htmlFor="batch_end">End Date</label>
                        <DatePickerComponent id="batch_end" name="booking_date_r" 
                            ref={dateEnd}
                            openOnFocus={onFocusEnd}
                            start={onFocusEnd}
                            value={AM_dateTime_ExtractDate(dataInfoPanel.info.end_date)}
                            change={(e) => changeFormValuesDatePicker(e, 'end_date')}
                            format='d-MMM-yyyy' 
                            placeholder='Enter date'
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-floating form-select-sm col-md-6" style={{left:'13px', marginTop: '4px'}}>
                    <select className="form-select form-select-sm" id="status"
                        value={dataInfoPanel.info.closed}
                        onChange={(e) => changeStatus(e, 'closed')}
                        >
                            <option value="-1" style={{background: '#ffc107'}}>Not in production</option>
                            <option value="0" style={{background: '#0d6efd', color: '#FFF'}}>In Progress</option>
                            <option value="1" style={{background: '#198754', color: '#FFF'}}>Closed</option>
                    </select>
                    <label htmlFor="status">Status</label>
                </div>
                <div className="form-floating form-select-sm col-6 mt-1" style={{paddingTop: '1px'}}>
                    <div className="col-md-6 mt-1" 
                        style={{border: '1px solid #CCC', width: '231px', marginLeft: '2px', borderRadius: '3px', height: '57px', padding: '4px'}}>
                        <div style={{fontSize: '12px', color: '#888', marginTop: '-3px'}}>Client dashboard (if available)</div>
                        <div className="form-check form-switch" style={{margin: '3px'}}>
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" 
                            checked={dataInfoPanel.info.show_in_dashboard === 1 ? true : false} style={{width: '40px', height: '20px'}}
                            onChange={(e) => changeSwitch(e, 'show_in_dashboard')}/>
                            <span style={{margin: '0px 10px', paddingTop: '3px', display: 'inline-block'}} className="form-check-label">Show in dashboard</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <hr />
    <div className="text-center">
        <button type="submit" className="btn btn-primary btn-sm mb-3" style={{'width': '100px'}} disabled={dataInfoPanel.disabled}>Apply</button>
    </div>
    </form>
    </>)} 
}

const ScriptForm = (props) => {

    const dateStart = useRef(null);
    const dateEnd = useRef(null);

    const onFocusStart = (args) => {
        dateStart.show();
    }
    const onFocusEnd = (args) => {
        dateEnd.show();
    }


    const [dataInfoPanel, setDataInfoPanel] = useState()

    useEffect(() => {
        if(props.data['info'] !== undefined){
            setDataInfoPanel(props.data)
        }
    }, [props])

    const changeStatus = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.target.value
            }
        }))
    }

    const changeFormValuesDatePicker = (event, field) => {
 
        setDataInfoPanel(previous => {

            const updatedInfo = {
                ...previous.info,
                [field]: reFormatDate(event.value)
            };
    
            if (field === 'script_start_date' || field === 'script_end_date') {



                const updatedAllScripts = previous.allScripts.map(script => ({
                    ...script,
                    language_start_date: field === 'script_start_date' && new Date(reFormatDate(event.value)) > new Date(script.language_start_date) 
                                        ? reFormatDate(event.value) 
                                        : reFormatDate(script.language_start_date),
                    language_end_date: field === 'script_end_date' && new Date(reFormatDate(event.value)) < new Date(script.language_end_date) 
                                        ? reFormatDate(event.value) 
                                        : reFormatDate(script.language_end_date)


                }));

                return {
                    ...previous,
                    info: updatedInfo,
                    allScripts: updatedAllScripts
                };
            } else {
                return {
                    ...previous,
                    info: updatedInfo
                };
            }
        });
    };

    const updateLanguage = (event, field, index) => {

        setDataInfoPanel(previous => {
            const updatedAllScripts = previous.allScripts.map((script, i) => {
                if (i === index) {
                    return {
                        ...script,
                        changed: true,
                        [field]: reFormatDate(event.value)
                    };
                } else {
                    return script;
                }
            });

            return {
                ...previous,
                allScripts: updatedAllScripts
            };
        });
    }

    const changeSwitch = (event, field) => {
        setDataInfoPanel(previous => ({
            ...previous,
            info: {
                ...previous.info,
                [field]: event.target.checked ? 1 : 0
            }
        }))
    }

    async function onSubmitHandler(e){
        e.preventDefault()
        
        let checkErrors = 0;
        if(dayjs(dataInfoPanel.info.script_start_date).isAfter(dayjs(dataInfoPanel.info.script_end_date))){
            checkErrors++;
        }

        if(checkErrors !== 0){
            toast.error('Check the dates entered of the Scripts. End Date is before Start Date');
            return
        }

        const checkLanguagesErrors = [];

        dataInfoPanel.allScripts.forEach(scrpt => {
            if(dayjs(scrpt.language_start_date).isAfter(dayjs(scrpt.language_end_date))){
                checkLanguagesErrors.push(scrpt.language_code)
            }
        })

        if(checkLanguagesErrors.length !== 0){
            toast.error(`Check the dates entered of the Languages (${checkLanguagesErrors.join(', ')}). End Date is before Start Date`);
            return
        }

        setDataInfoPanel(previous => ({
            ...previous,
            disabled: true
        }))

        props.sendDataParent(dataInfoPanel)
    }



    if(dataInfoPanel !== undefined){

        

        return (
        <><form onSubmit={(e) => onSubmitHandler(e)}><div className="modal-body" style={{padding: '7px 12px 0px 4px'}}>
        <div className="form-floating form-select-sm col-12">
            <input id="script_name" type="text" className="form-control" required={true} autoComplete="off" spellCheck="false"
            value={dataInfoPanel.info.script_name}
            onChange={(e) => changeStatus(e, 'script_name')} />
            <label htmlFor="script_name">Script Name</label>
        </div>
        <div className="row">
            <ToastContainer />
            <div className="col-md-6 mt-1" style={{border: '1px solid #CCC', width: '231px', marginLeft: '21px', borderRadius: '3px'}}>
            <div style={{fontSize: '12px', color: '#888', marginLeft: '-7px', marginTop: '2px'}}>Start Date (all script)</div>
            <DatePickerComponent id="script_start_date"                    
                            ref={dateStart}
                            openOnFocus={onFocusStart}
                            start={onFocusStart}
                            value={AM_dateTime_ExtractDate(dataInfoPanel.info.script_start_date)}
                            min={AM_dateTime_ExtractDate(dataInfoPanel.info.batch_start_date)}
                            max={AM_dateTime_ExtractDate(dataInfoPanel.info.batch_end_date)}
                            change={(e) => changeFormValuesDatePicker(e, 'script_start_date')}
                            format='d-MMM-yyyy' 
                            placeholder='Enter date'
            />
        </div>
            <div className="col-md-6 mt-1" style={{border: '1px solid #CCC', width: '231px', marginLeft: '10px', borderRadius: '3px'}}>
                <div style={{fontSize: '12px', color: '#888', marginLeft: '-7px', marginTop: '2px'}}>End Date (all script)</div>
                <DatePickerComponent id="script_end_date"
                            ref={dateEnd}
                            openOnFocus={onFocusEnd}
                            start={onFocusEnd}
                            value={AM_dateTime_ExtractDate(dataInfoPanel.info.script_end_date)}
                            min={AM_dateTime_ExtractDate(dataInfoPanel.info.batch_start_date)}
                            max={AM_dateTime_ExtractDate(dataInfoPanel.info.batch_end_date)}
                            change={(e) => changeFormValuesDatePicker(e, 'script_end_date')}
                            format='d-MMM-yyyy' 
                            placeholder='Enter date'
                />
            </div>
        </div>
        <div className="row">
            <div className="form-floating form-select-sm col-6 mt-1" style={{left:'13px'}}>
                <select className="form-select form-select-sm" id="status"
                    value={dataInfoPanel.info.script_status_id}
                    onChange={(e) => changeStatus(e, 'script_status_id')}
                    >
                        <option value="-1" style={{background: '#ffc107'}}>Not in production</option>
                        <option value="0" style={{background: '#0d6efd', color: '#FFF'}}>In Progress</option>
                        <option value="1" style={{background: '#198754', color: '#FFF'}}>Closed</option>
                    </select>
                    <label htmlFor="status">Status</label>
            </div>
            <div className="form-floating form-select-sm col-6 mt-0">
                <div className="col-md-6 mt-1" 
                        style={{border: '1px solid #CCC', width: '231px', marginLeft: '2px', borderRadius: '3px', height: '57px', padding: '4px'}}>
                    <div style={{fontSize: '12px', color: '#888', marginTop: '-3px'}}>Ingame</div>
                    <div className="form-check form-switch" style={{margin: '3px'}}>
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" 
                            checked={dataInfoPanel.info.is_game_content === 1 ? true : false} style={{width: '40px', height: '20px'}}
                            onChange={(e) => changeSwitch(e, 'is_game_content')}/>
                        <span style={{margin: '0px 10px', paddingTop: '3px', display: 'inline-block'}} className="form-check-label">Is Game Content</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="form-floating form-select-sm col-6 mt-0" style={{left:'13px'}}>
                <div className="col-md-6 mt-0" 
                        style={{border: '1px solid #CCC', width: '231px', marginLeft: '2px', borderRadius: '3px', height: '57px', padding: '4px'}}>
                    <div style={{fontSize: '12px', color: '#888', marginTop: '-3px'}}>Deactivable Lines</div>
                    <div className="form-check form-switch" style={{margin: '3px'}}>
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" 
                            checked={dataInfoPanel.info.deactivable === 1 ? true : false} style={{width: '40px', height: '20px'}}
                            onChange={(e) => changeSwitch(e, 'deactivable')}/>
                        <span style={{margin: '0px 10px', paddingTop: '3px', display: 'inline-block'}} className="form-check-label">Script lines deactivable </span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style={{padding: '8px'}}>
                Languages involved
                <table>
                    <tbody>
                        {dataInfoPanel.allScripts.map((script, index) => {
                            return <ScriptLineDetail key={index} data={script} element={index} onUpdateLanguage={updateLanguage}
                                    min={dataInfoPanel.info.script_start_date} 
                                    max={dataInfoPanel.info.script_end_date} />
                    })}
                    </tbody>
                </table>
            </div>         
        </div>
    </div>

    
    <hr />
    <div className="text-center">
        <button type="submit" className="btn btn-primary btn-sm mb-3" style={{'width': '100px'}} disabled={dataInfoPanel.disabled}>Apply</button>
    </div>
    </form>
    </>)}

}

const ScriptLineDetail = (props) => {

    const start_date = useRef(null);
    const end_date = useRef(null);

    const onFocusStart = (args) => {
        start_date.show();
    }
    const onFocusEnd = (args) => {
        end_date.show();
    }

    const min = new Date(props.min).toISOString().split('T')[0]
    const max = new Date(props.max).toISOString().split('T')[0]


    return (
        <tr style={{padding: '4px'}}>
        <td width="150">{props.data.language_code}</td>
        <td width="230">
            <DatePickerComponent width={'160px'}
                openOnFocus={onFocusStart}
                start={onFocusStart}
                min={min}
                max={max}
                value={AM_dateTime_ExtractDate(props.data.language_start_date)}
                change={(e) => props.onUpdateLanguage(e, 'language_start_date', props.element)}
                format='d-MMM-yyyy' 
                placeholder='Enter date' />
        </td>
        <td width="230">
            <DatePickerComponent width={'160px'}
                openOnFocus={onFocusEnd}
                start={onFocusEnd}
                min={min}
                max={max}
                value={AM_dateTime_ExtractDate(props.data.language_end_date)}
                change={(e) => props.onUpdateLanguage(e, 'language_end_date', props.element)}
                format='d-MMM-yyyy'
                placeholder='Enter date'/>
        </td>
        </tr>
    )

}


function AM_dateTime_ExtractDate(string){
    // Create a Date object from the MySQL string
    const jsDate = new Date(string);

    // Array of abbreviated month names
    const abbreviatedMonths = [
    "jan", "feb", "mar", "apr", "may", "jun",
    "jul", "aug", "sep", "oct", "nov", "dec"
    ];

    // Extract day, month, and year
    const day = jsDate.getDate();
    const month = abbreviatedMonths[jsDate.getMonth()];
    const year = jsDate.getFullYear();
    // Create the formatted string
    return `${day}-${month}-${year}`;
}

function reFormatDate(dateString) {


    const date = new Date(dateString);

    const year = date.getFullYear();
    // getMonth restituisce un valore da 0 (gennaio) a 11 (dicembre), quindi aggiungi 1
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Aggiungi uno zero iniziale se il mese o il giorno sono minori di 10
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
}


