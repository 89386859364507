import React, { createContext, useRef } from 'react';
import { API_ADR } from '../../ApiCenter/API_GET';
import axios from 'axios';
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const _ = require('lodash');
const SpellCheckContext = createContext();

const SpellCheckContextProvider = ({ children }) => {
    
    const spellCheckData = useRef({});
    const regexData = useRef(null);
    const dataSourceSpellCheck = useRef([]);
    const textToCheck = useRef({});
    const multiPackageResponse = useRef([]);
    const ignoreList = useRef({});
    const characterList = useRef({});
    const characterRegex = useRef({});
    const currentScriptIds = useRef(null);

    const searchTerms = (dictionary_code, text, prjId) => {
        const r = regexData.current ? regexData.current[dictionary_code] : null;
        if (!text || !r) { return null };
        text = '●' + text.replace(/[\s\n]/g, '●●') + '●';   
        
        if(characterRegex.current[prjId]) {
            const regex = new RegExp(`[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я](?:${characterRegex.current[prjId]})[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я]`, 'gi')
            text = text.replace(regex, '');
        }
        if (!text) { return null };

        const matches = [...new Set(text.toLowerCase().match(r))];

        if (matches.length>0) {
            const finalArray= [];
            matches.forEach (r => { 
                const trimTerm = r.slice(1,-1).replace(/●●/g, ' ');
                let term = spellCheckData.current[dictionary_code].find(g => g.term.toLowerCase() === trimTerm.toLowerCase());
                const scapeTerm=term.term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, '●●');             
                finalArray.push({scapeTerm: scapeTerm, term: term.term, sugestions: term.sugestions}); 
            });   
            if (finalArray) { 
                return finalArray; 
            }
        }
        return null;
    }

    const checkMisspells = (dataSource) => {
        if (!regexData.current) { 
            dataSourceSpellCheck.current = [];
            return; 
        };
        dataSource.forEach ((r) => {
            const matches = searchTerms(r.dictionary_code, r['target_asrec'], r.project_id);
            dataSourceSpellCheck.current[r.MyID] = matches ? matches : 1;
        });
    }

    const setRegex = (data) => {
        if (data) {
            let regexArray = [];
            for (const key in data) {
                if (data[key].length > 0) {
                    const scapeTerms = Array.from(data[key]).map(g => g.term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, '●●'));
                    regexArray[key] = new RegExp(`[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я](?:${scapeTerms.join('|')})[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я]`, 'gi');
                }
            } 
            regexData.current = regexArray;
        } else {
            regexData.current = null;   
        }
    }

    const deletePronunciations = (row) => {

        let text = row.target_asrec;
        if (!text) { return text }
        const pronunciations = row.pronunciations_data;
        const ignoreArray = ignoreList.current ? ignoreList.current[row.script_language_id] : null;
        //const characterArray = characterList.current ? characterList.current[row.project_id] : null;     
        if(!pronunciations && !ignoreArray && !characterList.current[row.project_id]) { return text }
        text = '●' + text.replace(/ /g, '●●') + '●';
        const pronunciationsTerms = pronunciations ? pronunciations.map(p => p.scapeTerm).sort((a, b) => b.length - a.length).join('|') : '';
        const ignoredTerms = ignoreArray ? ignoreArray.map(p => p.scaped_term).join('|') : '';
        //const characterTerms = characterArray ? characterArray.map(p => p.scaped_term).sort((a, b) => b.length - a.length).join('|') : '';
        const arr = [];
        if (pronunciationsTerms) { arr.push(pronunciationsTerms) };
        if (characterRegex.current[row.project_id]) { arr.push(characterRegex.current[row.project_id]) };
        if (ignoredTerms) { arr.push(ignoredTerms) };
        const ignoreTerms = arr.join('|');
        if (!ignoreTerms) { return row.target_asrec };
        const regex = new RegExp(`[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я](?:${ignoreTerms})[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я]`, 'gi')

        return text.replace(regex, ' ').replace(/●●/g, ' ').replace(/●/g, '');

    }

    const handleSpellCheck = async (pageDataSource, showSpellCheckMarks, setProgressBarValue, scriptIds, dataSourceLength) => {

        let ignoreChanges = false;
        let charactersChanges = false;

        const loadIgnoreList = async (scriptIds) => {  

            const result = await axios.get(API_ADR(`getIgnoreList=${scriptIds}`));

            if (result.data) { 
                if (result.data.ignorelist) {
                    const list = {};
                    result.data.ignorelist.forEach((r) => {
                        if(list.hasOwnProperty(r.script_language_id)) {
                            list[r.script_language_id].push({term: r.term, scaped_term: r.scaped_term});
                        } else {
                            list[r.script_language_id] = [{term: r.term, scaped_term: r.scaped_term}];
                        } 
                    });
                    ignoreChanges = !_.isEqual(ignoreList.current, list)
                    ignoreList.current = list;
                } else {
                    ignoreChanges = ignoreList.current ? true : false;
                    ignoreList.current = {}
                }
                if (result.data.characterlist) {
                    const charList = {};
                    result.data.characterlist.forEach((r) => {
                        const scapedName = r.character_name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, '●●')
                        if(charList.hasOwnProperty(r.project_id)) {
                            charList[r.project_id].push({term: r.character_name, scaped_term: scapedName});
                        } else {
                            charList[r.project_id] = [{term: r.character_name, scaped_term: scapedName}];
                        } 
                    });
                    charactersChanges = !_.isEqual(characterList.current, charList)
                    characterList.current = charList;
                    for (const pj in charList) {
                        if (charList.hasOwnProperty(pj)) {
                            const characterArray = characterList.current ? characterList.current[pj] : null; 
                            const characterTerms = characterArray ? characterArray.map(p => p.scaped_term).sort((a, b) => b.length - a.length).join('|') : '';
                            characterRegex.current[pj] = characterTerms;
                        }
                      }
                } else {
                    charactersChanges = characterList.current ? true : false;
                    characterList.current = {}
                    characterRegex.current = {}
                }
            } else {
                ignoreChanges = ignoreList.current ? true : false;
                charactersChanges = characterList.current ? true : false;
                ignoreList.current = {}
                characterList.current = {}
            };
        };

        if(currentScriptIds.current !== scriptIds) {
            clearSpellCheckData();
            currentScriptIds.current = scriptIds;
            dataSourceSpellCheck.current = new Array(dataSourceLength).fill(undefined);
        }
        
        await loadIgnoreList(scriptIds);

        const packages = [];
        let forSpellCheck = {};
        const limit = 5000;
        let packageSize = 0;
        //const start = performance.now();

        pageDataSource.forEach((r, index) => {
            const language = r['dictionary_code'];
            const id = r['MyID'];
            const analyzed = dataSourceSpellCheck.current[id];
            if(language && !analyzed){
                const text = deletePronunciations(r);
                if (!forSpellCheck[language]) { 
                    forSpellCheck[language] = '●' + r.MyID + '●' + text;
                } else {
                    forSpellCheck[language] += '●' + r.MyID + '●' + text;
                }
                packageSize += text ? text.length : 0;
                if (packageSize>limit || index === pageDataSource.length - 1) {
                    packages.push({...forSpellCheck});
                    forSpellCheck = {};
                    packageSize = 0;
                }
            }
        });

        const fetchSpellCheck = async (idx, pageDataSource, showSpellCheckMarks)  => {  

            let forSpellCheck = textToCheck.current[idx];
            const headers = { 'Content-Type': 'text/plain' };  
            const ObjectToDB = JSON.stringify(forSpellCheck).replace(/●\d+●/g, ' ');
            const link = API_ADR('spellCheck=1')
            await axios.post(
                    link,
                    ObjectToDB,
                    {headers}
            ).then(function (response) {
                setProgressBarValue((multiPackageResponse.current.length/textToCheck.current.length)*100, "Loading spellcheck", 1);
                multiPackageResponse.current.push(response.data);
                if (multiPackageResponse.current.length === textToCheck.current.length || !textToCheck.current[idx+1]) {
                    let joinedArray = {};
                    multiPackageResponse.current.forEach((r) => {
                        for (const key in r) {
                            if (joinedArray.hasOwnProperty(key)) {
                                joinedArray[key] = [...joinedArray[key], ...r[key]];
                            } else {
                                joinedArray[key] = [...r[key]];
                            }
                        }
                    });
                    for (const key in joinedArray) {
                        if(!spellCheckData.current.hasOwnProperty(key)) { spellCheckData.current[key] = [] } 
                        addToSpellCheckData(key, joinedArray)                      
                    };
                    multiPackageResponse.current = [];
                    checkMisspells(pageDataSource);
                    showSpellCheckMarks();
                    setProgressBarValue(-1, null, 1);
                } else {
                    idx+=1;
                    fetchSpellCheck(idx, pageDataSource, showSpellCheckMarks)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        if (packages.length>0) {
            textToCheck.current = packages;
            //await loadIgnoreList(scriptIds, pageDataSource, showSpellCheckMarks);
            fetchSpellCheck(0, pageDataSource, showSpellCheckMarks)
        } else {
            if ((ignoreChanges || charactersChanges) && pageDataSource) { 
                showSpellCheckMarks() 
            };
            setProgressBarValue(-1, null, 1);    
        }    
    }

    const checkUpdatedText = async (oldText, row, showSpellCheckMarks)  => {  

        const textToReplace = '●' + row.MyID + '●' + oldText;
        const replaceText = '●' + row.MyID + '●' + row.target_asrec;
        const language = row.dictionary_code;
        updateTextToCheck(textToReplace, replaceText, row.dictionary_code);

        const text = deletePronunciations(row);
        if(text === '') { 
            checkRowMisspells(row);
            return 
        }
        const forSpellCheck = {};
        forSpellCheck[language] = text;
        const headers = { 'Content-Type': 'text/plain' };  
        const ObjectToDB = JSON.stringify(forSpellCheck);
        const link = API_ADR('spellCheck=1')
        await axios.post(
                link,
                ObjectToDB,
                {headers}
        ).then(function (response) {          
           addToSpellCheckData(language, response.data);
           checkRowMisspells(row);
           showSpellCheckMarks();
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const updateTextToCheck = (oldText, newText, language) => {
        for (let i = 0; i < textToCheck.current.length; i++) {
            const p = textToCheck.current[i];          
            if (p[language] && p[language].includes(oldText)) {
              textToCheck.current[i][language] = p[language].replace(oldText, newText);
              break;
            }
        }
    }

    const addToSpellCheckData = (language, data) => {
        let update = false;
        data[language].forEach((d) => {
            if(!spellCheckData.current[language].find(p => p.term.toLowerCase() === d.term.toLowerCase())) {
                spellCheckData.current[language].push(d);
                update = true;
            }
        })  
        if (update) { 
            setRegex(spellCheckData.current);
        }; 
        return update;  
    }

    const checkRowMisspells = (r) => {
        const matches = searchTerms(r.dictionary_code, r['target_asrec'], r.project_id);
        const changed = dataSourceSpellCheck.current[r.MyID] !== matches;
        dataSourceSpellCheck.current[r.MyID] = matches;
        return changed;
    } 

    const clearSpellCheckData = () => {
        spellCheckData.current = {};
        regexData.current = null;
        dataSourceSpellCheck.current = [];
        textToCheck.current = {};
        multiPackageResponse.current = [];
        ignoreList.current = {};
        characterList.current = {};
    }

  return (
    <SpellCheckContext.Provider value={{
         currentScriptIds, spellCheckData, dataSourceSpellCheck, handleSpellCheck, clearSpellCheckData, checkUpdatedText, ignoreList, characterList 
         }}>
      {children}
    </SpellCheckContext.Provider>
  );

};

export { SpellCheckContext, SpellCheckContextProvider };