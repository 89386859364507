import axios from 'axios';
import $ from 'jquery';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { API_ADR, SpinnerCentered } from '../../ApiCenter/API_GET';
import { ChipListComponent, ChipsDirective, ChipDirective } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Search, Inject, Filter, Toolbar, } from '@syncfusion/ej2-react-grids';

function fullListUsers(allTheUsers, existingUsers, projectBatchScript) {

    let existingUsersArray = existingUsers && existingUsers.length> 0 ? existingUsers.split(',') : [];
    for (let obj of allTheUsers) {

      if (existingUsersArray.includes(obj.id)) {
        obj.used = true;
      } else {
        obj.used = false;
      }
      if(projectBatchScript !== 'script'){

        if(obj.external === '1' && obj.superadmin === '0'){
            obj.used = true;
        }
      }
    }
    return allTheUsers;

}


function selectExistingUsers(allTheUsers, existingUsers){

    try{
        if(existingUsers.length === 0){
            return []
        }
        var arr = allTheUsers.filter(item => existingUsers.split(',').indexOf(item.id) !== -1);
        return arr
    }catch(e){
        return []
    }
}

export const ProjectsShareForm = forwardRef((props, ref) => {

    let chipsActorToAdd

    const [errorMessage, setErrorMessage] = useState('');
    const [infoOperation, setInfoOperation] = useState(props.typeofOp);
    const [listResources, setListResources] = useState({});
    //const [groupInfo, setGroupInfo] = useState({});
    const [chips, setChips] = useState([]);
    const [chipForm, setChipForm] = useState([]);

    const defaultSettings = {
        showModal: false,
        isLoading: false,
        selectedID: null,
        disabledButton: true
    }

    const [modalSettings, setModalSettings] = useState(defaultSettings);


    useImperativeHandle(ref, () => ({
        openShareModal(props) {

            setChips(previous => [])

            setInfoOperation(props)
            
            setModalSettings(previous => ({
                ...previous,
                isLoading: true,
            }))
        }
    }));

    useEffect(() => {
      
        const fetchUrl = async () => {
            setChips(previous => [])
            const headers = {
                'Content-Type': 'text/plain'
            };
            var ObjectToDB = JSON.stringify(infoOperation);
            await axios.post(
                API_ADR(`projectList_SharingUsers=1`),
                ObjectToDB,
                {headers}
            ).then(function (result) {

                setListResources(fullListUsers(result.data.listOfResources, result.data.groupInfo.group_id, infoOperation.infoLevel))
                setChips(selectExistingUsers(result.data.listOfResources, result.data.groupInfo.group_id))
                setChipForm(selectExistingUsers(result.data.listOfResources, result.data.groupInfo.group_id))

                setModalSettings(previous => ({
                    ...previous,
                    isLoading: false,
                    showModal: true
                }))
            })
        };
        if(modalSettings.isLoading){
            fetchUrl();
        }       
    // eslint-disable-next-line
    }, [modalSettings]);


    /* Add username Chip*/
    const addChips = (args) => {
        chipsActorToAdd.add({ text: args.group_user_name, id: args.id });
        updateLines(args.id)
        
        setChipForm([...chipForm, {
            id: args.id,
            group_user_name: args.group_user_name,
            used: true 
        }])

        setModalSettings(previous => ({
            ...previous,
            disabledButton: false
        }))
    }

    /* Delete username  Chip*/
    const deleteEventChips = (args) => {
        const arr = chipForm.filter(item => args.data.id.indexOf(item.id) === -1)
        setChipForm(arr)
        updateLines(args.data.id)
        setModalSettings(previous => ({
            ...previous,
            disabledButton: false
        }))
    }

    function updateLines(userid) {
        const newArr = listResources.map((el,index)=>{
        if(el.id===userid){
            return {...el,used:!el.used}
        }
        else return el;
        })
        setListResources(newArr)
    }

    /* submit form */
    const handleSubmit = (event) => {
        event.preventDefault();

        const userSiteId = listResources && listResources.length>0 ? listResources[0].user_site_id : '-1';

        let userSelected = []
        chipForm.forEach(element => {
            userSelected.push(element.id)
        })

        const objectToDB = {
            ...infoOperation,
            users: userSelected,
            userSiteId: userSiteId
        }
        setChips(previous => [])
        setChipForm(previous => [])
        setModalSettings(previous => ({
            ...previous,
            disabledButton: true
        }))
        sharingOperation(objectToDB)
    }

    /* QUERY UPDATE OR INSERT*/
    async function sharingOperation(object){
        const headers = {
            'Content-Type': 'text/plain'
        };
    
        var ObjectToDB = JSON.stringify(object);
 
        const link = API_ADR(`sharingOperation=1`)
    
            // send the username and password to the server
        // eslint-disable-next-line
        const response = await axios.post(
                link,
                ObjectToDB,
                {headers}
        ).then(function (response) {

           setModalSettings(defaultSettings)
           closeModal('reload'); // CLOSE WINDOW

        })
    }

    const templateLine = (props) => {
        let iconUserGroup = props.is_group === '1' ? 
            props.external === '1' ? 
                {icon: 'bi bi-people-fill', title: 'External Group', color: '#cf0000', button: 'danger'} 
                : 
                {icon: 'bi bi-people-fill', title: 'Internal Group', color: '#0d6efd', button: 'primary'} 
            : 
            props.external === '1' ? 
                {icon: 'bi bi-person-fill', title: 'External User' , color: '#cf0000', button: 'danger'} 
                 : 
                {icon: 'bi bi-person-fill', title: 'Internal User', color: '#0d6efd', button: 'primary'} 

        return (
            <>
           
            <button type="button" className={`btn btn-sm btn-outline-${iconUserGroup.button} btn-sm`} style={{"fontSize": '0.8em', 'height': '20px', 'padding':'1px 4px'}} disabled={props.used} onClick={() => {addChips(props)}}>Add</button>
            {props.used ? 
                <span style={{"marginLeft": "10px", 'opacity': '0.5'}} title={iconUserGroup.title}><i className={iconUserGroup.icon} style={{fontSize: '15px', color: iconUserGroup.color}}></i> {props.group_user_name}</span> 
                : 
                <span style={{"marginLeft": "10px", 'fontSize': '0.9em'}} title={iconUserGroup.title}><i className={iconUserGroup.icon} style={{fontSize: '15px', color: iconUserGroup.color}}></i> {props.group_user_name}</span>}
            </>
        )
    }

    /* search bar key up event*/
    $(document).off('keyup', '.e-input.e-search').on('keyup', '.e-input.e-search', function (e) {
        $('.e-input-group-icon.e-search-icon.e-icons').click()
        $(this).focus()
    })

    /* close modal */
    const closeModal = (reload) => {
        deleteChipsSupport()
        setModalSettings(defaultSettings)
        setErrorMessage('')
        props.close(reload)
    }

    function deleteChipsSupport(){
        var elements = document.querySelectorAll('.e-chip-delete.e-dlt-btn');
        elements.forEach(function(element) {
            element.click();
        });
    }


    if(modalSettings.isLoading === true){return <SpinnerCentered />}

    return (

        modalSettings.showModal ?

            <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content" id="AudioMateWindowMenu" >
                        <div className="modal-header" style={{padding: '7px 10px 5px 16px'}}>
                            <h5 className="modal-title">
                             <i className="bi bi-share" style={{marginRight: '4px'}}></i> Share resource for {infoOperation.infoLevel.charAt(0).toUpperCase() + infoOperation.infoLevel.slice(1)} "{infoOperation.nameElement}"
                            </h5>
                            {<span style={{fontSize: '0.8em', color: 'red'}}>{errorMessage}</span>}
                            <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal('')}>🗙</button>    
                        </div>
                        <div className="modal-body" style={{"maxHeight": 570, 'overflow': 'auto'}}>
                            <form className="row g-3" onSubmit={handleSubmit} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}>
                                <div className="col-md-6 border p-0 m-l-4">
                                    <div className="e-control e-toolbar e-lib e-keyboard border-bottom" style={{"height": "auto", "width": "auto"}}>
                                        <div style={{'margin': '12px 14px'}}>Users and Teams enabled</div>
                                    </div>
                                 
                                    <ChipListComponent name="chip-avatar" id="chip-avatar" enableDelete={true} selection="Single"
                                    ref={g => chipsActorToAdd = g}
                                    delete={deleteEventChips}>
                                    <ChipsDirective> 
                                        {chips.map((value, index) => { 
                                            return <ChipDirective key={index} text={value.group_user_name} id={value.id} />; 
                                        })} 
                                    </ChipsDirective> 
                                    </ChipListComponent>

                                </div>
                                <div id="shareListUsers" className="col-md-6">
                                    
                                    <GridComponent 
                                        dataSource={listResources} 
                                        //allowPaging={true} 
                                        //pageSettings={pageOptions} 
                                        //editSettings={editOptions} 
                                        /*toolbar={toolbarOptions} */
                                        //actionComplete={completeEdit}
                                        //commandClick={commandClick}
                                        height={320}
                                        toolbar={['Search']}>
                                        <ColumnsDirective>
                                        {/*nombre, lenguaje, y podemos poner gender de momento*/}
                                            <ColumnDirective field='group_user_name' headerText='Resource Name' template={templateLine} width='300' />

                                        </ColumnsDirective>
                                        <Inject services={[Search, Toolbar, Filter]}/>
                                    </GridComponent>
                                </div>

                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-sm" disabled={modalSettings.disabledButton} style={{width: '90px'}}>Apply</button>
                                </div>
                            </form>   
                        </div>
                    </div>
                </div>
            </div>
        : null
        )
})

